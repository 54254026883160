import React, {useState} from 'react';


function Todo({todo, deleteHandler, updateHandler }) {

const [isEditing, setIsEditing] = useState(false)
const [updatedTodo, setUpdatedTodo] = useState() 

const UpdateTodoState = (e) => {
// update the state in with the value entered in the input

setUpdatedTodo({
  id: todo.id,
  message: e.target.value
})
}

const updateAndReset = (input, e) => {
  e.preventDefault()
  // call update handler with the input
  updateHandler(input)
  setIsEditing (false)
}





  return (
    <div >
      {isEditing ?

      <form onSubmit={e => updateAndReset(updatedTodo, e)}>
       <input 
       type="text"
       defaultValue={todo.message}
       onChange={UpdateTodoState}
       />
       </form> 
       :
       <p onDoubleClick={() => setIsEditing(true)}>{todo.message}</p>
         
        }
     
        <button onClick={() => deleteHandler(todo.id)}> x </button>
    
    </div>
  )
};

export default Todo;
  