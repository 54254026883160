import React, {useState, useEffect} from 'react';
import TodoForm from './todoForm';
import TodoList from './TodoList';
import axios from 'axios';





function Todos() {
  const [todoList, setTodoList] = useState({});

useEffect(() => {
axios.get('http://localhost:888/todos', {})
.then(res => {
  console.log(res)
})
.catch (err => {
  console.log(err)
})
  
},[])


  const deleteHandler = id => {
//  filter method
const newTodos = todoList.filter(item => {
  return item.id !== id
})

setTodoList(newTodos)
  }

 const updateHandler = todo => {
// update logic here
setTodoList(todoList.map(item => {
  if (item.id === todo.id) {
    return {
      ...item,
      message:todo.message
    }
  } else {
    return item
  }
}))



}
  return (
    <div >
      <TodoForm todos={todoList} setTodos={setTodoList}/>
      <TodoList todos={todoList}   deleteHandler={deleteHandler} updateHandler={updateHandler}/>
    </div>
  )
}

export default Todos;
  